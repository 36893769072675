(function () {
  'use strict';

  angular.module('app')
    .config(LoginRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function LoginRoutes($stateProvider) {
    $stateProvider
      .state('login', {
        url: '/login',
        component: 'login'
      });
  }
})();
