angular
  .module('app')
  .component('register', {
    templateUrl: 'app/modules/register/register.html',
    controller: RegisterCtrl
  });

function RegisterCtrl($log, $state, $scope, UserService) {
  var vm = this;

  vm.invalidLoginMessage = null;
  vm.userData = {
    login: '',
    password: '',
    email: ''
  };

  vm.errorMessages = {
    invalidData: 'Błędny login lub hasło',
    noStations: 'Użytkownik nie ma udostępnionej żadnej stacji'
  };

  vm.register = function () {
    UserService.register(vm.userData.login, vm.userData.password, vm.userData.email).then(function (response) {
      if (!response) {
        vm.invalidLoginMessage = vm.errorMessages.invalidData;
      } else {
        $state.go('app.list');
      }
    });
  };

}
