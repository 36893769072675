angular
  .module('app')
  .component('modify', {
    templateUrl: 'app/modules/app/modify/modify.html',
    controller: ModifyCtrl
  });

function ModifyCtrl($state, $stateParams, $log, $scope, $rootScope) {
  var vm = this;
  vm.id = $scope.id;

  if ($scope.id) {
    $rootScope.title = 'Edycja listy';
  } else {
    $rootScope.title = 'Dodawanie listy';
  }
}
