(function () {
  'use strict';

  angular.module('app')
    .config(RegisterRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function RegisterRoutes($stateProvider) {
    $stateProvider
      .state('register', {
        url: '/register',
        component: 'register'
      });
  }
})();
