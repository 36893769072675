(function () {
  'use strict';

  angular.module('app')
    .config(ModifyRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function ModifyRoutes($stateProvider) {
    $stateProvider
      .state('app.modify', {
        url: '/modify/{id:int}',
        component: 'modify'
      })
      .state('app.add', {
        url: '/add',
        component: 'modify'
      });
  }
})();
