(function () {
  'use strict';

  angular.module('app').directive('animateOnChange', animateOnChange);

  /**
   * @ngInject
   */
  function animateOnChange($timeout) {
    return {
      // require: 'ngModel',
      link: function (scope, element, attrs) {
        // function clearIfEmpty() {
        //   console.log('test');
        //   var clearFieldAttr = attrs.clearField;
        //   var c = scope.$eval(clearFieldAttr);
        //   if (c) {
        //     modelCtrl.$setViewValue('');
        //     modelCtrl.$render();
        //   }
        // }

        scope.$watch(attrs.animateOnChange, function (nv, ov) {
          if (nv !== ov) {
            element.addClass('changed');
            $timeout(function () {
              element.removeClass('changed');
            }, 1000); // Could be enhanced to take duration as a parameter
          }
        });
      }
    };
  }

})();
