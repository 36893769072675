angular
  .module('app')
  .component('login', {
    templateUrl: 'app/modules/login/login.html',
    controller: LoginCtrl
  });

function LoginCtrl($log, $state, UserService) {
  var vm = this;

  vm.invalidLoginMessage = null;
  vm.userData = {
    login: '',
    password: ''
  };

  vm.errorMessages = {
    invalidData: 'Błędny login lub hasło',
    noStations: 'Użytkownik nie ma udostępnionej żadnej stacji'
  };

  vm.login = function () {
    UserService.login(vm.userData.login, vm.userData.password).then(function (response) {
      if (!response) {
        vm.invalidLoginMessage = vm.errorMessages.invalidData;
      } else {
        $state.go('app.list');
      }
    });
  };

}
